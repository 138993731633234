
import {computed, defineComponent, ref} from "vue";
import EntityView from "@/components/base/common/EntityView.vue";
import {
  ClaimContact,
  CompanyContact,
  LoadBaseModal,
  LoadEntity,
  LoadPanel,
  LoadTagModal
} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {onUpdated} from "@vue/runtime-core";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import WatchAlert from "@/views/metaEntity/WatchAlert.vue";
import MenuWatch from "@/views/metaEntity/MenuWatch.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import Warnings from "@/views/note/Warnings.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormNote from "@/views/activity/FormNote.vue";
import ClaimService from "@/core/services/ClaimService";
import FormMoveClaim from "@/views/claim/FormMoveClaim.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import Froala from "@/components/base/editor/Froala.vue";
import AttachmentFileButton from "@/components/base/upload/AttachmentFileButton.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import Duration from "@/views/activity/Duration.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import ClaimStatusMsg from "@/views/claim/ClaimStatusMsg.vue";
import MenuLink from "@/components/base/action/MenuLink.vue";
import TagEntities from "@/views/tag/TagEntities.vue";
import Swal from "sweetalert2";
import {useToast} from "vue-toast-notification";
import ScanEventMenu from "@/views/scan/ScanEventMenu.vue";
import Row from "@/components/base/common/Row.vue";
import TemplateListSelect from "@/components/common/TemplateListSelect.vue";
import EntityProcessList from "@/views/process/EntityProcessList.vue";

export default defineComponent({
  name: "Claim",
  components: {
    EntityProcessList,
    TemplateListSelect,
    Row,
    ScanEventMenu,
    TagEntities,
    MenuLink,
    ClaimStatusMsg,
    DateTimeFormat,
    Duration,
    FormErrorMessage,
    BaseRadio,
    AttachmentFileButton,
    Froala,
    BaseForm,
    BaseSelect,
    FormMoveClaim,
    FormNote,
    BaseModal,
    Warnings,
    EntityLoading,
    MenuWatch, WatchAlert, Avatar, TabNavigate, QuickAction, EntityView
  },
  setup() {
    const loading = ref(true);
    const templateSelected = ref<any>();
    const noteModel = ref({text: '', claimId: ''})
    const emailRequest = ref({subject: '', body: '', type: 'PATIENT', emailTo: '', docs: ''})
    const attachments = ref<any[]>([])
    const files = ref<any>([]);
    const submitting = ref(false);
    const claim = computed(() => store.state.ClaimModule.claim);
    const entityLoading = computed(() => store.state.LoadingModule.entityLoading);
    const link = ref('');
    const user = computed(() => store.getters.currentUser);
    const currentId = computed(() => store.state.TemplateModule.clientId);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Claim Details', [
        {link: true, router: '/policies/' + claim.value?.policy?.id, text: 'View Policy'},
        {link: false, router: '', text: 'View Claim'}
      ])
    })
    return {
      attachments,
      noteModel,
      claim,
      ...LoadEntity(Actions.LOAD_CLAIM),
      entityLoading,
      ...LoadPanel(),
      ...LoadBaseModal(),
      ...CompanyContact(''),
      ...ClaimContact(''),
      ...LoadTagModal(),
      emailRequest,
      submitting,
      currentId,
      files,
      loading,
      templateSelected,
      link,
      user,
    }
  },
  methods: {
    showLink(id) {
      this.loading = true;
      const baseModal = this.$refs.secureLinkRef as typeof BaseModal
      baseModal.showBaseModal()
      ClaimService.secureLink(id).then(res => {
        this.link = res;
      }).finally(() => {
        this.loading = false;
      })
    },
    onContactSelect(email) {
      this.emailRequest.emailTo = email;
    },
    onTemplateSelect(temp) {
      this.submitting = true;
      this.emailRequest.body = temp.body;
      this.emailRequest.subject = temp.subject;
      ClaimService.emailFormat(this.claim.id, this.emailRequest).then(res => {
        this.emailRequest.body = res.body
      }).finally(() => {
        this.submitting = false;
      })
    },
    onRemove(index) {
      this.attachments.splice(index, 1);
    },
    onSendEmail() {
      this.loadClaimContact(this.claim.id)
      this.loadCompanyContact(this.claim.client.id);
      this.templateSelected = ''
      const baseModal = this.$refs.sendClaimAckRef as typeof BaseModal
      baseModal.showBaseModal()
      this.files = []
      this.emailRequest = {subject: '', body: '', type: 'PATIENT', emailTo: '', docs: ''}
      const select = this.$refs.templateEmailSelect as typeof TemplateListSelect
      select.clear();
      this.attachments = [];
    },
    onSubmitSend() {
      this.submitting = true;
      if (this.emailRequest.body === '') {
        useToast().warning('Please input message body ', {position: "top-right"})
        return
      }
      this.emailRequest.docs = this.files.map(item => item.id).join(",");
      ClaimService.sendAck(this.claim.id, this.emailRequest, this.attachments).then(() => {
        this.submitting = false
        const baseModal = this.$refs.sendClaimAckRef as typeof BaseModal
        baseModal.hideBaseModal()
        useToast().success('Email Sent!!!', {position: "top-right"})
      }).finally(() => {
        this.submitting = false;
      })
    },
    addWarning() {
      this.noteModel.text = ''
      this.noteModel.claimId = this.claim.id
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.showBaseModal()
    },
    onSaveWarning() {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal();
      const warning = this.$refs.warningsRef as typeof Warnings
      warning.paginationLoad();
    },
    updateStatus(status) {
      ClaimService.updateStatus(this.claim.id, {status: status}).then(() => {
        this.reloadData()
      })
    },
    onSelectedFile(file) {
      this.attachments.push(file)
    },
    move() {
      const baseModal = this.$refs.formMoveClaimRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    onRemoveFile(index) {
      this.files.splice(index, 1);
    },
    onMoved() {
      this.reloadData()
      const baseModal = this.$refs.formMoveClaimRef as typeof BaseModal
      baseModal.hideBaseModal()
    },
    onScan(claim) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      ClaimService.scan(claim.id).then(() => {
        useToast().success('Scan successfully', {position: 'top-right'})
      }).finally(() => {
        Swal.close();
      })
    },
    buildNav(claim) {
      let nav = [
        {id: 'main-overview', router: `/claims/` + claim.id, name: 'Overview'},
        {id: 'claimReserve', router: `/claims/` + claim.id + '/reserve', name: 'Reserve'},
      ]
      if (claim.customer) {
        nav.push(
          {id: 'claimCustomer', router: `/claims/` + claim.id + '/customer', name: 'Customer'},
        )
      }
      nav.push(
        {id: 'claimAdjudication', router: `/claims/` + claim.id + '/adjudication/summary', name: 'Adjudication'},
      )
      nav.push(
        {id: 'claimRecovery', router: `/claims/` + claim.id + '/recovery/summary', name: 'Recovery'},
      )
      nav.push(
        {id: 'claimInvoices', router: `/claims/` + claim.id + '/invoices', name: 'Invoices'},
        {id: 'claimActivities', router: `/claims/` + claim.id + '/activities', name: 'Activities'},
        {id: 'claimEmail', router: `/claims/` + claim.id + '/emails', name: 'Emails'},
        {id: 'claimDocument', router: `/claims/` + claim.id + '/documents', name: 'Documents'},
        {id: 'claimTasks', router: `/claims/` + claim.id + '/tasks', name: 'Tasks'},
        {id: 'claimProcess', router: `/claims/` + claim.id + `/processes`, name: 'Processes'},
        {id: 'claimAnalytics', router: `/claims/` + claim.id + '/analytics', name: 'Analytics'},
      )
      return nav;
    }
  }
})
